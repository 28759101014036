:root {
  --main-bg-color: #fdf2ff;
  --main-backround-color: white;
  --main-bg-color-hover:#f6d5fc;
}
.App {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: calc(6.5px + 1.4vmin);
  
}
.navbar-header {
  font-size: calc(8px + 1.4vmin);
}
.collapsing {
  transition: none !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1.3vmin);
  color: white;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0;
  padding-top: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;

}
.card {
  margin-top: 0.5%;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
html{scroll-behavior:smooth}
body{padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0;
  padding-top: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
#hidden{
  display: none;
}
#Bodyy{
  background-color: var(--main-backround-color);
  
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0;
  padding-top: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar{
  font-size: calc(5px + 1vmin);

}
#NavBar{
  
}
#Contents{
  content: fit;
  text-align: right;

  
} 
#Brand{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  
}
.navbar-toggler-icon{
  width:calc(20px + 0.5vw) !important;
  height:calc(20px + 0.5vh) !important;
  min-width: 25 !important;
  min-height: 25 !important;
  z-index: 2;
}
#ToggleBtn{
  float:none; 
  z-index: 2;

}
#Setmore_button_iframe{
  float:none; 
  z-index: 2;
  
  display: 'flex';

  content: fit;

  /* for the floating button
  position: fixed; 
  right: -2px; 
  top: 25%; 
  display: block; 
  z-index: 20000
  */
}
#logo{
  content: fit;
  max-height: 65px;
  padding-right: 12%;


}
#Spy{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.responsive{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
#Main_Image{
  
  width: 100%;
  min-width: 306px;
  padding-left: 4%;
  
}
#Main_Image_Mobile{
  
  width: 100%;
  
}
#NoMarginNoPadding{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
#about-par{
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
  font-size: 1em;
  
}
#about-color-margin{

  padding-top: 4%;
  padding-bottom: 7%;

}
#gallery-margin{
  padding-top: 7%;
  padding-bottom: 2%;

}

#Gallery_margin{

  margin-top: 1%;
  margin-bottom: 1%;

  padding-left: 3%;
  margin-left: 0;
  margin-right: 0;
  display: block;
  min-height: 1px;
  width: 100%;

  overflow: auto;
}
#Timetable_image{
margin-left: 2%;
}
#Timetable_Borders{
  padding-left: 10%;
  text-align: left;

  
}
#timetable-margin{

  padding-top: 7%;
  padding-bottom: 2%;
}
#timetable_image{
  height: auto;
  object-fit: cover;
  max-height: 100vh; 
}
#Services_margin{
  padding-top: 4%;
  padding-bottom: 1%;
}

#Services_letters{
  font-size: calc(5px + 1.9vmin);
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}
#Services_color{
  background-color: var(--main-bg-color); 
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 1%;
  padding-left: 1%;
}
#text_left{
  text-align: left;
}
#text_right{
  text-align: right;
}
#Services_header{
  padding-top: 3%;
  padding-bottom: 3%;
}
#Maps_Iframe_margin{
  margin-left: 1%;

}
#ContactFrame{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0;
  padding-top: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
#Maps{
  width: 45vw;
  height: 30vw;
  border: 0;

}
#Contact_Borders{
  padding-left: 10%;
  text-align: left;
  padding-right: 0px;
  padding-bottom: 0;
  padding-top: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
#Footer_color{
  background-color: #1d0c1d;

}

.nav-item {
  position: relative;
  padding: 15px 0;
}

.nav-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #000;
}
.nav-link.active {
  color: blue
}

.nav-link:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--main-bg-color-hover);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-link:hover:after,
.nav-link.active:after {
  width: 100%;
  left: 0;
  background: var(--main-bg-color-hover);
}
a:visited{
  color : black;
}
a:link{
  color : black;
}
a{
  color : black;
}
#Footer_margin{
  padding-top: 3%;
}
#Footer_letters{
  font-size: calc(5px + 1vmin);
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 5%;
  padding-bottom: 1px;


  color:white;
}
#telephon{
  color:white;
}
#Rights{
  font-size: calc(5px + 0.6vmin);
}
#AllComponents{
  top: -1000px;
}
#CollapseContent{
  font-size: 1em;
}